import React from "react"
import Loadable from "react-loadable"
import "styles/global.scss"


const loader = () => <div>Loading.</div>

const Container = Loadable({
    loader: () => import("containers/Chapter6"),
    loading: loader,
  })

const Chapter6 = () => {
    return (
        <Container />
    )
}

export default Chapter6;